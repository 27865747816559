.get_in_touch_section {
  margin-top: 80px;
  margin-bottom: 70px;
  
  text-align: center;
  color: #797979;
  width: 100%;

  .get_in_touch_heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      font-size: 25px;
      font-weight: normal;
    }
    h5 {
      margin-top: 20px;
      font-size: 16px;
      font-weight: bold;
    }
  
    img {
      margin-top: 20px;
      width: 50%;
    }
  }

  .get_in_touch_section_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 10%;
    color: #797979;
    h2{
      margin-top: 20px;
      font-size: 25px;
      font-weight: normal;
    }
    h3{
      margin-top: 20px;
      font-size: 16px;
      font-weight: bold;
    }
    .get_in_touch_forms {
      width: 100%;
      margin-top: 20px;
      width: 80%;
      .form_item {
        width: 100%;
        margin-top: 40px;

        input {
          width: 100%;
          border: 0.5px solid #00ff00;
          outline: none;
          border-radius: 8px;
          height: 40px; 
        }

        input.notvalid {
          border-color: red;
        }

        textarea {
          width: 100%;
          border: 0.5px solid #00ff00;
          outline: none;
          border-radius: 8px;
          height: 100px;
          padding: 5px;
        }

        #message {
          // height: 100px;
          // text-align: left;
        }

      }

      button {
        outline: none;
        border: none;
        cursor: pointer;
        margin-top: 20px;
        color: #ffffff;
        font-weight: 600;
        font-size: 20px;
        background-color: #00ff00;
        width: 150px;
        height: 50px;
        opacity: 0.6;
        transition: 0.5s;
        border-radius: 14px;
        // z-index: 3000;
      }
  
      button:hover {
        border-radius: 0;
      }

      #form_item_phone {
        // margin-top: 0;
        display: flex;
        #phone_options {
          width: 85px;
          border: 0.5px solid #00ff00;
          outline: none;
          border-radius: 8px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;

        }
        #phone {
          width: 93%;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }

        #phone_notvalid {
          border-color: red;
        }
        #phone_options_notvalid {
          border-color: red;
        }
      }
    }
  }

  

  
}
.input_error {
  // border-color: red;
  // background-color: rgba(red, 0.4);
  text-decoration: underline red;
  color: red;
}


@media screen and (max-width: 700px) {
  .get_in_touch_section {
    .get_in_touch_section_content {
      .get_in_touch_forms {
        button {
          width: 100px;
          height: 40px;
        }
      }
    }
  }
  
}