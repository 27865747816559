.header_container {
  position: fixed;
  top: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 300px;
  width: 100%;
  height: 80px;
  background-color: #00ff00;
  opacity: 1;
  color: #797979;
  overflow: hidden;


  .header_logo {
    cursor: pointer;
    transition: 0.3s;
  }

  .header_logo:hover {
    border: 1px solid #b5b;
    border-radius: 10px;
  }

  .header_navigation {
    a {
      color: #797979;
      font-size: 20px;
      font-weight: 500;
      margin-left: 75px;
      transition: 0.3s;
    }

    a:hover {
      color: #ffffff;
    }
  }
}

@media screen and (max-width: 1500px) and (min-width: 1000px) {
  .header_container {
    padding-right: 30px;
  }
}

@media screen and (max-width: 1000px) {
  .header_container {
    padding-right: 30px;
    .header_navigation {
      display: none;
    }
    .header_mobile_menu {
      width: 55px;
      height: 55px;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 1000px) {
  .header_container {
    .header_mobile_menu {
      display: none;
    }
  }
}

.toggleLeft {
  animation: rotate1 0.7s linear
}

@keyframes rotate1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.toggleRight {
  animation: rotate2 0.7s linear
}

@keyframes rotate2 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.header_navigation_mobile {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.7);
  color: #ffffff;
  z-index: 1000000000000000;
  transition: 0.7s;
}

.header_navigation_mobile_open {
  top: 0;
  right: 0;
}

.close_btn_container {
  width: 100%;
    display: flex;
    justify-content: flex-end;
  span {
    
    color: #FFFFFF;
    font-size: 50px;
    cursor: pointer;
    margin-right: 25px;
    margin-top: 15px;
  }
}

.mobile_navigation_items {
  color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    margin-top: 30px;
    font-size: 40px;
    text-decoration: none;
    color: #ffffff;
    transition: 0.4s;
  }

  a:hover {
    color: #00ff00;
  }
}
