.container {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 100000;
  background-color: #797979;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* HTML: <div class="loader"></div> */
.loader {
  width: 70px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #ccc #0000;
  animation: l16 1s infinite linear;
}
.loader::before,
.loader::after {    
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}
.loader::before {
  border-color: #00ff00 #0000;
  animation: inherit; 
  animation-duration: .5s;
  animation-direction: reverse;
}
.loader::after {
  margin: 8px;
}
@keyframes l16 { 
  100%{transform: rotate(1turn)}
}

.message_container {
  position: fixed;
  left: calc(50% - 150px);
  top: calc(50% - 270px);
  z-index: 4555555555;
  width: 300px;
  height: 550px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0);
  opacity: 0.8;
  // overflow: hidden;

  .successful, .unsuccessful {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 20px 40px;

    h6 {
      font-size: 25px;
      font-weight: normal;
    }
    p {
      font-size: 16px;
      font-weight: bold;

      span {
        cursor: pointer;
        color: #00ff00;
      }
    }
    button {
      outline: none;
      border: none;
      cursor: pointer;
      margin-top: 20px;
      color: #ffffff;
      font-weight: 600;
      font-size: 20px;
      background-color: #00ff00;
      width: 150px;
      height: 50px;
      opacity: 0.6;
      transition: 0.5s;
      border-radius: 14px;
      // z-index: 3000;
    }

    button:hover {
      border-radius: 0;
    }
  }
}
