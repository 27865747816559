.page_container {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  // padding-top: 79px;
  //heto stugel sax kara bugi patchar lini yndhanur saytum ***
}

* {
  box-sizing: border-box;
}
