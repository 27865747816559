  .carousel_section_info {
    position: absolute;
    z-index: 100;
    background-color: black;
    left: 0px;
    top: 80px;
    width: 100%;
    height: 900px;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #FFFFFF;

    h1 {
      font-size: 25px;
      font-weight: 600;

      span {
        color: #00ff00;
      }
    }

    h2 {
      margin-top: 20px;
      width: 30%;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
    }

    button {
      outline: none;
      border: none;
      cursor: pointer;
      margin-top: 20px;
      color: #00ff00;
      font-weight: 600;
      font-size: 20px;
      background-color: #FFFFFF;
      width: 220px;
      height: 50px;
      opacity: 1;
      transition: 0.5s;
      // z-index: 3000;
    }

    button:hover {
      border-radius: 14px;
      border: 1px solid #00ff00;
    }
  }

  @media screen and (max-width: 1400px) {
    .carousel_section_info {
      height: 700px;
    }
  }  

  @media screen and (max-width: 1100px) {
    .carousel_section_info {
      height: 600px;
    }
  }

  @media screen and (max-width: 800px) {
    .carousel_section_info {
      height: 500px;
      h2 {
        margin-top: 10px;
        width: 70%;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
      }
      button {
        width: 180px;
        height: 40px;
        font-size: 18px
      }
    }
  }

  @media screen and (max-width: 600px) {
    .carousel_section_info {
      height: 400px;
      h2 {
        margin-top: 10px;
        width: 70%;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
      }
      button {
        width: 150px;
        height: 35px;
        font-size: 16px
      }
    }
  }

  @media screen and (max-width: 500px) {
    .carousel_section_info {
      height: 300px;
    }
  }