.carousel_container {
  display: flex;
  width: 400%;
  height: 900px;
  margin-top: 80px;
  overflow: hidden;
  position: relative;
  animation-name: example;
  animation-duration: 20s;  
  animation-delay: 3s;
  animation-iteration-count: infinite;
  

  .carousel_item {    
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
    img:hover {
    }
  }
}

@keyframes example {
  0% {left: 0px;}
  32% {left: -100%}
  64% {left: -200%}
  96% {left: -300%}
  100% {left: 0px}
}

@media screen and (max-width: 1400px) {
  .carousel_container {
    height: 700px;
  }
}

@media screen and (max-width: 1100px) {
  .carousel_container {
    height: 600px;
  }
}

@media screen and (max-width: 800px) {
  .carousel_container {
    height: 500px;
  }
}

@media screen and (max-width: 600px) {
  .carousel_container {
    height: 400px;
  }
}

@media screen and (max-width: 500px) {
  .carousel_container {
    height: 300px;
  }
}