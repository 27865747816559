.about_our_work_section {
  margin-top: 50px;
  width: 100%;
  // height: 550px;
  background-color: #00ff00;
  padding: 20px 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.6;

  h2 {
    font-size: 25px;
    font-weight: normal;
    color: #ffffff
  }

  .about_our_work_content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    width: 100%;
    justify-content: space-between;


    .about_our_work_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      text-align: center;
      width: 300px;
      height: 550px;
      padding-bottom: 70px;
      color: #ffffff;
      margin: 40px;
      h3 {
        font-size: 25px;
        height: 60px;
      }
      h4 {
        height: 150px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}


@media screen and (max-width: 1850px) {
  .about_our_work_section{
    width: 100%;
    .about_our_work_content {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;
      
      .about_our_work_item {
        margin: 40px;
        width: 500px;
        height: 350px;
      }
  
    }
  }
  
}

@media screen and (max-width: 1550px) {
  .about_our_work_section{
    width: 100%;
    .about_our_work_content {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;
      
      .about_our_work_item {
        margin: 40px;
        width: 100%;
        height: 250px;
        padding-bottom: 10px;
        margin-top: 10px;
      }
  
    }
  }
  
}

@media screen and (max-width: 1000px) {
  .about_our_work_section{
    width: 100%;
    .about_our_work_content {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      // align-content: center;
      
      .about_our_work_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px auto;
        width: 100%;
        height: 350px;
        padding-bottom: 10px;

        h3 {
          font-size: 25px;
          height: 60px;
        }
        h4 {
          height: 150px;
          font-size: 16px;
          font-weight: bold;
          width: 80%;
        }
      }
  
    }
  }
  
}
