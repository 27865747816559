.our_process_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 20px 10%;
  width: 100%;

  h3 {
    font-size: 25px;
    font-weight: normal;
    color: #797979
  }

  .our_process_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 70px;
    width: 100%;

    .our_process_item {
      height: 500px;
      width: 250px;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
      background-image: url('https://i.ibb.co/fk7Tk6k/rectangle-For-Processes.png');
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: auto;
      padding: 5px 10px;

      .our_process_item_heading {
        display: flex;
        justify-content: space-between;

        p {
          font-size: 20px;
          color: #797979
        }
      }
      .our_process_item_content {
        margin-top: 70px;
        color: #ffffff;
        padding: 0 20px;

        h3 {
          font-size: 25px;
        }
        ul {
          margin-top: 30px;
          list-style-type: disc;
          font-size: 16px;
          font-weight: bold;
          li {
            margin-top: 10px;
            list-style-type: disc;
            color: #ffffff;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 1500px) {
  .our_process_section {
    width: 100%;
    .our_process_content {
      width: 100%;
      .our_process_item {
        margin: 20px 10%;
      }
    }
  }
  
}

@media screen and (max-width: 1100px) {
  .our_process_section {
    width: 100%;
    .our_process_content {
      width: 100%;
      .our_process_item {
        margin: 20px 20px;
      }
    }
  }
  
}

@media screen and (max-width: 800px) {
  .our_process_section {
    padding: 20px 2%;
    width: 100%;
    .our_process_content {
      width: 100%;
      .our_process_item {
        margin-top: 20px;
      }
    }
  }
  
}

@media screen and (max-width: 600px) {
  .our_process_section {
    width: 100%;
    .our_process_content {
      width: 100%;
      flex-direction: column;
      align-items: center;
      .our_process_item {
        margin: 20px 20px;
      }
    }
  }
  
}
