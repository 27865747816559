.about_us_section {
  display: flex;
  width: 100%;
  background-color: #00ff00;
  opacity: 0.6;
  margin-top: 80px;
  img {
    width: 50%;
  }
  .about_us_content {
    width: 50%;
    color: #797979;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 70px;
    text-align: center;

    h3 {
      font-size: 25px;
    }
    p {
      margin-top: 70px;
      font-size: 18px;
      font-weight: bold;
    }
  }
}


@media screen and (max-width: 1500px) {
  .about_us_section {
    display: flex;
    flex-direction: column;
    img {
      width: 100%;
    }
    .about_us_content {
      width: 100%;

      p {
        margin-top: 40px;
      }
    }
  }
  
}

@media screen and (max-width: 700px) {
  .about_us_section {
    display: flex;
    flex-direction: column;
    img {
      width: 100%;
    }
    .about_us_content {
      width: 100%;
      padding: 10px;

      p {
        margin-top: 10px;
      }
    }
  }
  
}
