.footer {
  background-color: #0c0f14;
  color: #ffffff;
  // margin-top: 70px;
  // height: 150px;

  .footer_social {
    margin-top: 20px;
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;

    img {
      cursor: pointer;
    }

    div {
      display: flex;
      align-items: center;
      cursor: pointer;
      h1 {
        margin-left: 5px;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }
    a {
      color: #ffffff;
      display: flex;
      align-items: center;
      cursor: pointer;
      h1 {
        margin-left: 5px;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }

    
  }

  .footer_copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 10px;
    text-align: center;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (max-width: 700px) {
  .footer {
    .footer_social {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto;

      img,div {
        margin: 10px auto;
      }
    }
  }
}
