.servicesPage {
  margin-top: 80px;
  position: relative;
  .servicesPage_leftMenu {
    width: 25%;
    position: fixed;
    top: 80px;
    left: -25%;
    z-index: 1000000;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    height: 100%;
    transition: 1s;
    overflow: hidden;

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 30px auto;
      list-style: none;
      text-align: start;

      li {
        text-align: center;
        font-size: 25px;
        margin-top: 40px;
        cursor: pointer;
        transition: 0.2s;
      }

      li:hover {
        color: #00ff00;
      }
    }

    .arrow {
      position: fixed;
      z-index: 10000000;
      left: -25px;
      top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      background-color: #797979;
      color: #ffffff;
      border-radius: 10px;
      transition: 1s;
      cursor: pointer;
    }

    .arrow_open {
      left: calc(25% - 25px);
    }
  }

  .servicesPage_leftMenu_tablet {
    width: 40%;
    position: fixed;
    top: 80px;
    left: -40%;
    z-index: 1000000;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    height: 100%;
    transition: 1s;
    overflow: hidden;

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 30px auto;
      list-style: none;
      text-align: start;

      li {
        text-align: center;
        font-size: 25px;
        margin-top: 40px;
        cursor: pointer;
        transition: 0.2s;
      }

      li:hover {
        color: #00ff00;
      }
    }

    .arrow {
      position: fixed;
      z-index: 10000000;
      left: -25px;
      top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      background-color: #797979;
      color: #ffffff;
      border-radius: 10px;
      transition: 1s;
      cursor: pointer;
    }

    .arrow_open {
      left: calc(40% - 25px);
    }
  }
  .servicesPage_leftMenu_mobile {
    width: 100%;
    position: fixed;
    top: 80px;
    left: -100%;
    z-index: 1000000;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    height: 100%;
    transition: 1s;
    overflow: hidden;

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 30px 10px;
      list-style: none;
      text-align: start;

      li {
        text-align: center;
        font-size: 25px;
        margin-top: 40px;
        cursor: pointer;
        transition: 0.2s;
      }

      li:hover {
        color: #00ff00;
      }
    }

    .arrow {
      position: fixed;
      z-index: 10000000;
      left: -25px;
      top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      background-color: #797979;
      color: #ffffff;
      border-radius: 10px;
      transition: 1s;
      cursor: pointer;
    }

    .arrow_open {
      left: calc(100% - 25px);
    }
  }

  .servicesPage_leftMenu_open {
    left: 0;
  }

  .servicesPage_content {
    margin: 50px;

    .servicesPage_Item {
      margin-top: 100px;
      border: 2px solid #00ff00;
      border-radius: 15px;
      color: #797979;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      padding: 25px 25px;

      .img_container {
        img {
          width: 120px;
          height: 120px;
        }
      }

      h1 {
        font-size: 25px;
        font-weight: 600;
      }

      p {
        margin-top: 20px;
      }

      p.texts {
        font-size: 16px;
        font-weight: 600;
      }
      p.textList {
        font-size: 20px;
      }
      p.endText {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .servicesPage {
    margin-top: 80px;
    position: relative;
    .servicesPage_leftMenu {
      width: 25%;
      position: fixed;
      top: 80px;
      left: -25%;
      z-index: 1000000;
      background-color: rgba(0, 0, 0, 0.8);
      color: #ffffff;
      height: 100%;
      transition: 1s;
      overflow: hidden;
  
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 30px auto;
        list-style: none;
        text-align: start;
  
        li {
          text-align: center;
          font-size: 25px;
          margin-top: 40px;
          cursor: pointer;
          transition: 0.2s;
        }
  
        li:hover {
          color: #00ff00;
        }
      }
  
      .arrow {
        position: fixed;
        z-index: 10000000;
        left: -25px;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 50px;
        background-color: #797979;
        color: #ffffff;
        border-radius: 10px;
        transition: 1s;
        cursor: pointer;
      }
  
      .arrow_open {
        left: calc(25% - 25px);
      }
    }
  
    .servicesPage_leftMenu_tablet {
      width: 40%;
      position: fixed;
      top: 80px;
      left: -40%;
      z-index: 1000000;
      background-color: rgba(0, 0, 0, 0.8);
      color: #ffffff;
      height: 100%;
      transition: 1s;
      overflow: hidden;
  
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 30px auto;
        list-style: none;
        text-align: start;
  
        li {
          text-align: center;
          font-size: 25px;
          margin-top: 40px;
          cursor: pointer;
          transition: 0.2s;
        }
  
        li:hover {
          color: #00ff00;
        }
      }
  
      .arrow {
        position: fixed;
        z-index: 10000000;
        left: -25px;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 50px;
        background-color: #797979;
        color: #ffffff;
        border-radius: 10px;
        transition: 1s;
        cursor: pointer;
      }
  
      .arrow_open {
        left: calc(40% - 25px);
      }
    }
    .servicesPage_leftMenu_mobile {
      width: 100%;
      position: fixed;
      top: 80px;
      left: -100%;
      z-index: 1000000;
      background-color: rgba(0, 0, 0, 0.8);
      color: #ffffff;
      height: 100%;
      transition: 1s;
      overflow: hidden;
  
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 30px 10px;
        list-style: none;
        text-align: start;
  
        li {
          text-align: center;
          font-size: 25px;
          margin-top: 40px;
          cursor: pointer;
          transition: 0.2s;
        }
  
        li:hover {
          color: #00ff00;
        }
      }
  
      .arrow {
        position: fixed;
        z-index: 10000000;
        left: -25px;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 50px;
        background-color: #797979;
        color: #ffffff;
        border-radius: 10px;
        transition: 1s;
        cursor: pointer;
      }
  
      .arrow_open {
        left: calc(100% - 25px);
      }
    }
  
    .servicesPage_leftMenu_open {
      left: 0;
    }
  
    .servicesPage_content {
      margin: 50px;
  
      .servicesPage_Item {
        width: 100%;
        margin-top: 100px;
        border: 2px solid #00ff00;
        border-radius: 15px;
        color: #797979;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        padding: 25px 5px;
  
        .img_container {
          img {
            width: 100px;
            height: 100px;
          }
        }
  
        h1 {
          font-size: 25px;
          font-weight: 600;
        }
  
        p {
          margin-top: 20px;
        }
  
        p.texts {
          font-size: 16px;
          font-weight: 600;
        }
        p.textList {
          font-size: 20px;
        }
        p.endText {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
}