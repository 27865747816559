.our_services_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 10%;
  margin: 50px auto;

  h1 {
    font-size: 25px;
    font-weight: normal;
    color: #797979;
  }

  .our_services_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;

    .service_card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      text-align: center;
      width: 380px;
      height: 600px;
      border-radius: 16px;
      margin: 20px;
      cursor: pointer;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      transition: 0.1s;
      padding: 0 20px;

      img {
        width: 100px;
        height: 100px;
      }

      h1 {
        margin-top: 60px;
        height: 100px;
      }

      h2 {
        height: 220px;
        width: 95%;
        margin-top: 30px;
        color: #797979;
      }

      button {
        outline: none;
        border: none;
        color: #00ff00;
        cursor: pointer;
      }
    }

    .service_card:hover {
      border: 0.5px solid #00ff00;
      border-radius: 0;
    }
  }
}

@media screen and (max-width: 1080px) {
  .our_services_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .our_services_content {
      flex-direction: column;
      align-items: center;
      width: 100%;
  
      .service_card {
        width: 100%;
        height: 450px;
        padding: 20px 20px;
        align-items: center;
        margin: 20px 0;
      }
    }
  }
  
}

@media screen and (max-width: 800px) {
  .our_services_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .our_services_content {
      flex-direction: column;
      align-items: center;
      width: 100%;
  
      .service_card {
        width: 100%;
        height: 550px;
        padding: 20px 20px;
        align-items: center;
        margin: 20px 0;
      }
    }
  }
  
}